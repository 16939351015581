import React from 'react';
import {FlagWithCategory} from '../../hooks/use-get-vetting-categories/useGetVettingCategories';
import ProfileVettingContentItem from '../profile-vetting-content-item';

interface ProfileVettingFlaggedContentProps {
  flaggedContent: FlagWithCategory[];
  onPostClick: (flag: string, postId: string) => void;
}

function ProfileVettingFlaggedContent(
  props: ProfileVettingFlaggedContentProps
) {
  const {flaggedContent, onPostClick} = props;
  return (
    <div>
      {flaggedContent.map((item, index) => (
        <ProfileVettingContentItem
          key={item.id}
          item={item}
          isFirst={index === 0}
          onPostClick={onPostClick}
        />
      ))}
    </div>
  );
}

export default ProfileVettingFlaggedContent;
