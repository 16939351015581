import React from 'react';
import FailedScanNote from '@/components/creator-vetting-report/components/failed-scan-note';
import {FailedFlagWithCategory} from '../../hooks/use-get-vetting-categories/useGetVettingCategories';
import ProfileVettingContentItem from '../profile-vetting-content-item';
import styles from './ProfileVettingFailedContent.module.scss';

interface ProfileVettingFlaggedContentProps {
  failedContent: FailedFlagWithCategory;
  onPostClick: (flag: string, postId: string) => void;
}

function ProfileVettingFailedContent(props: ProfileVettingFlaggedContentProps) {
  const {failedContent, onPostClick} = props;

  return (
    <div className={styles.profileVettingFailedContentContainer}>
      <FailedScanNote />
      <ProfileVettingContentItem
        key={failedContent.id}
        item={failedContent}
        isFirst
        showFlagName={false}
        showTopDivider={false}
        onPostClick={onPostClick}
      />
    </div>
  );
}

export default ProfileVettingFailedContent;
