import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';
import {TimeRange} from '@/types/timeRange';
import {Severity} from '@/components/creator-vetting-report/constants';

/**
 * Creator vetting report store - tracks settings for each creator vetting report
 */
const DEFAULT_REPORT_SETTINGS = {
  id: '',
  hoveredPostId: null,
  seeAllFlagPosts: {},
  timeRangeFilter: null,
  severityFilter: Severity.ALL,
};

export interface ReportSettings {
  id: string;
  hoveredPostId: string | null;
  seeAllFlagPosts: {
    [flagId: string]: boolean;
  };
  timeRangeFilter: TimeRange | null;
  severityFilter: Severity;
}

export type CreatorVettingReportStoreState = {
  reports: {[key: string]: ReportSettings};
};

const useCreatorVettingReportStore = create<CreatorVettingReportStoreState>()(
  immer(() => ({
    reports: {},
  }))
);

const initializeReportSettings = (reportId: string) => {
  useCreatorVettingReportStore.setState((draft) => {
    const report = getReportSettings(reportId);
    draft.reports[reportId] = {...report, id: reportId, timeRangeFilter: null};
  });
};

const getReportSettings = (reportId: string) => {
  return (
    useCreatorVettingReportStore.getState().reports[reportId] ||
    DEFAULT_REPORT_SETTINGS
  );
};

const setHoveredPostId = (reportId: string, hoveredPostId: string | null) => {
  useCreatorVettingReportStore.setState((draft) => {
    const report = getReportSettings(reportId);
    draft.reports[reportId] = {...report, hoveredPostId};
  });
};

const setSeeAllFlagPosts = (
  reportId: string,
  flagId: string,
  seeAll: boolean
) => {
  useCreatorVettingReportStore.setState((draft) => {
    const report = getReportSettings(reportId);
    draft.reports[reportId] = {
      ...report,
      seeAllFlagPosts: {
        ...report.seeAllFlagPosts,
        [flagId]: seeAll,
      },
    };
  });
};

const setReportTimeRangeFilter = (reportId: string, timeRange: TimeRange) => {
  useCreatorVettingReportStore.setState((draft) => {
    const report = getReportSettings(reportId);
    draft.reports[reportId] = {
      ...report,
      timeRangeFilter: timeRange,
    };
  });
};

const setReportSeverityFilter = (reportId: string, severity: Severity) => {
  const report = getReportSettings(reportId);

  useCreatorVettingReportStore.setState((draft) => {
    draft.reports[reportId] = {
      ...report,
      severityFilter: severity,
    };
  });
};

export const creatorVettingReportGetters = {
  getReportSettings,
};

export const creatorVettingReportActions = {
  initializeReportSettings,
  setHoveredPostId,
  setSeeAllFlagPosts,
  setReportTimeRangeFilter,
  setReportSeverityFilter,
};

export default useCreatorVettingReportStore;
