import React, {useState} from 'react';
import {designSystemToken, Label} from '@lightricks/react-design-system';
import translate from '@/utils/translate';
import Conditional from '@/components/conditional';
import Divider from '@/components/divider';
import useParams from '@/hooks/use-params';
import useCreatorVettingReportStore, {
  creatorVettingReportActions,
  ReportSettings,
} from '@/stores/creatorVettingReportStore';
import {
  FailedFlagWithCategory,
  FlagWithCategory,
} from '../../hooks/use-get-vetting-categories/useGetVettingCategories';
import ProfileVettingContentItemPosts from '../profile-vetting-content-item-posts';
import styles from './ProfileVettingContentItem.module.scss';

const TRANSLATION_PREFIX =
  'components.creator-vetting-report.components.profile-vetting-content-item';

function ContentItemHeader(props: {
  category: string;
  flag: string;
  report: ReportSettings;
  postsAmount: number;
  seeAll: boolean;
  setSeeAll: (seeAll: boolean) => void;
  showFlagName?: boolean;
  showTopDivider?: boolean;
}) {
  const {
    category,
    flag,
    report,
    postsAmount,
    seeAll,
    setSeeAll,
    showFlagName = true,
    showTopDivider = true,
  } = props;
  return (
    <div className={styles.contentItemHeader}>
      {showTopDivider ? <Divider margin={0} /> : null}
      <div
        className={`${styles.category} ${showFlagName ? '' : styles.hidden}`}
      >
        <Label size="sm" color={designSystemToken('semantic.fg.primary')}>
          {category}
        </Label>
      </div>
      <div className={styles.flag}>
        <div
          className={`${styles.flagNameAndCount} ${
            showFlagName ? '' : styles.hidden
          }`}
        >
          <Label size="lg" color={designSystemToken('semantic.fg.primary')}>
            {flag}
          </Label>
          <Label size="lg" color={designSystemToken('semantic.fg.secondary')}>
            ({postsAmount})
          </Label>
        </div>
        <Conditional condition={seeAll}>
          <Label
            className={styles.seeLess}
            size="md"
            color={designSystemToken('semantic.fg.secondary')}
            onClick={() => {
              setSeeAll(false);
              if (report?.id) {
                creatorVettingReportActions.setSeeAllFlagPosts(
                  report?.id,
                  flag,
                  false
                );
              }
            }}
          >
            {translate(`${TRANSLATION_PREFIX}.see-less`)}
          </Label>
        </Conditional>
      </div>
    </div>
  );
}

function ProfileVettingContentItem(props: {
  item: FlagWithCategory | FailedFlagWithCategory;
  isFirst?: boolean;
  showFlagName?: boolean;
  showTopDivider?: boolean;
  onPostClick: (flag: string, postId: string) => void;
}) {
  const {item, isFirst, showFlagName, showTopDivider, onPostClick} = props;
  const {reportId} = useParams();
  const creatorVettingReportState = useCreatorVettingReportStore();
  const report = creatorVettingReportState.reports[reportId];
  const [seeAll, setSeeAll] = useState(
    report?.seeAllFlagPosts[item.id] || false
  );

  return (
    <div className={styles.profileVettingContentItemContainer}>
      <ContentItemHeader
        category={item.category.name}
        flag={item.name}
        report={report}
        postsAmount={item.postsAmount}
        seeAll={seeAll}
        setSeeAll={setSeeAll}
        showFlagName={showFlagName}
        showTopDivider={showTopDivider}
      />
      <ProfileVettingContentItemPosts
        posts={item.posts}
        flag={item.id}
        report={report}
        seeAll={seeAll}
        setSeeAll={setSeeAll}
        onPostClick={onPostClick}
      />
      {!isFirst ? <Divider margin={0} /> : null}
    </div>
  );
}

export default ProfileVettingContentItem;
